import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/projects/projectsActions";
import { ProjectFileEditDialogHeader } from "./ProjectFileEditDialogHeader";
import { ProjectFileEditForm } from "./ProjectFileEditForm";
import { useProjectFilesUIContext } from "../ProjectFilesUIContext";

export function ProjectFileEditDialog() {
  const filesUIContext = useProjectFilesUIContext();
  const filesUIProps = useMemo(() => {
    return {
      id: filesUIContext.selectedId,
      setIds: filesUIContext.setIds,
      projectId: filesUIContext.projectId,
      queryParams: filesUIContext.queryParams,
      showEditFileDialog: filesUIContext.showEditFileDialog,
      closeEditFileDialog: filesUIContext.closeEditFileDialog,
      initFile: filesUIContext.initFile,
      newFile: filesUIContext.newFile,
    };
  }, [filesUIContext]);

  const dispatch = useDispatch();
  const { actionsLoading, projectFileForEdit, session } = useSelector(
    (state) => ({
      actionsLoading: state.projects.actionsLoading,
      projectFileForEdit: state.projects.projectForEdit?.current?.files?.find(
        (file) => file.id === filesUIProps.id
      ),
      session: state.auth.session,
    }),
    shallowEqual
  );

  useEffect(() => {
    //dispatch(actions.fetchFile(filesUIProps.id));
    console.log("projectFilesUIProps.id:", filesUIProps.id);
  }, [filesUIProps.id]);

  const saveFile = (projectFile) => {
    console.log("saveFile body: ", projectFile);
    if (!filesUIProps.id) {
      dispatch(actions.uploadFile(projectFile, session?.id)).then(() => {
        filesUIProps.setIds([]);
        filesUIProps.closeEditFileDialog();
      });
    } else {
      dispatch(actions.updateFile(projectFile)).then(() => {
        filesUIProps.setIds([]);
        filesUIProps.closeEditFileDialog();
      });
    }
  };

  return (
    <Modal
      show={filesUIProps.showEditFileDialog}
      onHide={filesUIProps.closeEditFileDialog}
      centered
      scrollable
      backdrop="static"
      size={"lg"}
    >
      <ProjectFileEditDialogHeader
        projectFileForEdit={projectFileForEdit}
        actionsLoading={actionsLoading}
        showEditFileDialog={filesUIProps.showEditFileDialog}
      />
      <ProjectFileEditForm
        saveFile={saveFile}
        actionsLoading={actionsLoading}
        file={projectFileForEdit || filesUIProps.initFile}
        onHide={filesUIProps.closeEditFileDialog}
        newFile={filesUIProps.newFile}
      />
    </Modal>
  );
}

import * as React from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { LeadFileEditDialogHeader } from "./LeadFileEditDialogHeader";
import { LeadFileEditForm } from "./LeadFileEditForm";
import { useLeadFilesUIContext } from "../LeadFilesUIContext";
import { FormattedMessage } from "react-intl";
import { LeadFileInvoiceForm } from "./invoice/LeadFileInvoiceForm";

export function LeadFileEditDialog() {
  const {
    selectedId,
    showEditFileDialog,
    closeEditFileDialog,
    initFile,
    newFile,
    isInvoice,
    setIsInvoice,
    triggerFormSubmit,
    isSubmitDisabled,
    submitButtonLabel,
    footerRef,
  } = useLeadFilesUIContext();
  const { actionsLoading, leadFileForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.leads.actionsLoading,
      leadFileForEdit: state.leads.leadForEdit?.current?.files?.find(
        (file) => file.id === selectedId
      ),
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (leadFileForEdit?.fileType === "INVOICE") {
      setIsInvoice(true);
    }
  }, [leadFileForEdit]);

  return (
    <Modal
      show={showEditFileDialog}
      onHide={closeEditFileDialog}
      centered
      scrollable
      backdrop="static"
      size={"lg"}
    >
      <LeadFileEditDialogHeader
        leadFileForEdit={leadFileForEdit}
        actionsLoading={actionsLoading}
        showEditFileDialog={showEditFileDialog}
      />
      {isInvoice ? (
        <LeadFileInvoiceForm file={leadFileForEdit || initFile} />
      ) : (
        <LeadFileEditForm file={leadFileForEdit || initFile} />
      )}
      <Modal.Footer className={"align-items-end"}>
        <div ref={footerRef} className={"flex-grow-1"}></div>
        <div className="ml-auto">
          <button
            type="button"
            onClick={closeEditFileDialog}
            disabled={actionsLoading}
            className="btn btn-light btn-elevate mr-2"
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <button
            disabled={isSubmitDisabled}
            type="submit"
            onClick={triggerFormSubmit}
            className="btn btn-primary btn-elevate"
          >
            {submitButtonLabel ? (
              <FormattedMessage id={submitButtonLabel} />
            ) : newFile ? (
              <FormattedMessage id="FILE.ACTIONS.UPLOAD.NEW" />
            ) : (
              <FormattedMessage id="COMMON.ACTION.SAVE" />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

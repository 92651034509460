import { API } from "@aws-amplify/api";

import { addInformationFields } from "app/_utils/fileUtils";

export async function createLead(lead) {
  const body = { ...lead };
  return await API.post("API", "/leads", { body });
}

export async function updateLead(lead) {
  delete lead.createdByUser;
  return await API.post("API", `/leads/${lead.id}`, { body: lead });
}

export async function getLeadById(id) {
  return await API.get("API", `/leads/${id}`, {});
}

export async function findLeads(queryParams) {
  let apiResponse;
  if (!!queryParams?.projectId) {
    apiResponse = await API.get("API", `/projects/${queryParams.projectId}/leads`, {});
    console.log("List leads by PROJECT ID:", apiResponse);
  } else if (!!queryParams?.productId) {
    apiResponse = await API.get("API", `/products/${queryParams.productId}/leads`, {});
    console.log("List leads by PRODUCT ID:", apiResponse);
  } else if (!!queryParams?.userId) {
    apiResponse = await API.get("API", `/users/${queryParams.userId}/leads`, {});
    console.log("List leads by USER ID:", apiResponse);
  } else {
    apiResponse = await API.get("API", "/leads", {});
  }
  return apiResponse;
}

export async function getLeadComplete(id) {
  return await API.get("API", `/leads/${id}`, {});
}

export async function deleteLead(id) {
  return await API.del("API", `/leads/${id}`, {});
}

export async function createLeadUser(leadUser) {
  return await API.post("API", "/leads", { body: leadUser });
}

export const createFile = async (file, sessionId) => {
  const fileForCreation = { ...file };
  addInformationFields(fileForCreation, sessionId);

  const response = await API.post("API", `/leads/${fileForCreation.leadId}/files`, {
    body: fileForCreation,
  });
  console.log(response);
  console.log(`${response.url}`);

  try {
    const fetchResponse = await fetch(response.url, {
      method: "PUT",
      body: fileForCreation.file,
      headers: {
        "Content-Type": "",
      },
    });
    console.log("fetchResponse:", fetchResponse);
  } catch (e) {
    console.log("axios error:");
    console.log(e);
  }
  return response;
};

export const updateFile = async (file) => {
  return await API.post("API", `/leads/${file.leadId}/files/${file.id}`, {
    body: file,
  });
};

export const getFile = async (file) => {
  return await API.get("API", `/leads/${file.leadId}/files/${file.id}`, {});
};

export const deleteFile = async (file) => {
  return await API.del("API", `/leads/${file.leadId}/files/${file.id}`, {});
};

export const createFinancialDocument = async (financialDocument, sessionId) => {
  const fileForCreation = { ...financialDocument };
  if (financialDocument.file) {
    addInformationFields(fileForCreation, sessionId);
  }
  const response = await API.post("API", `/leads/${fileForCreation.leadId}/financialDocuments`, {
    body: fileForCreation,
  });
  if (financialDocument.file) {
    try {
      const fetchResponse = await fetch(response.url, {
        method: "PUT",
        body: fileForCreation.file,
        headers: {
          "Content-Type": "",
        },
      });
      console.log("fetchResponse:", fetchResponse);
    } catch (e) {
      console.log("axios error:");
      console.log(e);
    }
  }
  return response;
};

export const updateFinancialDocument = async (financialDocument) => {
  return await API.post(
    "API",
    `/leads/${financialDocument.leadId}/financialDocuments/${financialDocument.id}`,
    {
      body: financialDocument,
    }
  );
};

export const unselectSelectedForFinance = async (file) => {
  return await API.del("API", `/leads/${file.leadId}/files/${file.id}/selectedForFinance`, {});
};

export const getAuditTrail = async (id, lastEvaluatedKey, fetchDirection, sizePerPage) => {
  return await API.get("API", `/audit`, {
    queryStringParameters: {
      entity: "LEAD",
      id,
      fetchDirection: fetchDirection ?? "next",
      GSI4PK: lastEvaluatedKey?.GSI4PK,
      GSI4SK: lastEvaluatedKey?.GSI4SK,
      PK: lastEvaluatedKey?.PK,
      SK: lastEvaluatedKey?.SK,
      sizePerPage: sizePerPage,
    },
  });
};

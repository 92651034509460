import * as requestFromServer from "./productsCrud";

import { save, saved } from "redux/global/globalActions";

import { requestFileSignature, cancelFileSignaturesRequest } from "app/_utils/signatureUtils";

import {
  processSnackbarNotification,
  SNACKBAR_MESSAGE,
} from "app/modules/Common/SnackbarNotificationsHandler";

import { projectsSlice } from "../projects/projectsSlice";

import { callTypes, productsSlice } from "./productsSlice";

const contextualActions = {
  default: productsSlice("products").actions,
  projectProducts: productsSlice("projectProducts").actions,
};

const actions = contextualActions.default;

const { actions: actionProject } = projectsSlice;

export const fetchProducts =
  (queryParams = {}, projectProducts, context = "default", removeWon = false) =>
  (dispatch) => {
    dispatch(contextualActions[context].startCall({ callType: callTypes.list }));
    // This condition is used to determine if products data is present or not.
    // In case it is present (projectProducts NOT undefined),
    // fetching products via API is not required and the already available products data is used instead
    if (queryParams.projectId && projectProducts) {
      dispatch(contextualActions[context].productsFetched({ entities: projectProducts }));
    } else {
      return requestFromServer
        .findProducts(queryParams)
        .then((response) => {
          const entities = response.data.items
            // Filter by removeWon
            .filter((item) => !removeWon || !item.leadStatus?.WON)
            // Filter by projectId in case that user will create property from project and come back to project
            .filter((item) => !queryParams.projectId || item.projectId === queryParams.projectId);
          dispatch(contextualActions[context].productsFetched({ entities }));
        })
        .catch((error) => {
          // error.displayMessage = "Can't find products";
          dispatch(contextualActions[context].catchError({ error, callType: callTypes.list }));
          processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_PRODUCTS, dispatch);
        });
    }
  };

export const fetchProduct = (id) => (dispatch) => {
  if (!id) {
    dispatch(actions.productFetched({ productForEdit: undefined }));
    return Promise.resolve(undefined);
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductById(id)
    .then((response) => {
      const productForEdit = response;
      console.log("fetchLeadProduct productForEdit: ", productForEdit);
      dispatch(actions.productFetched({ productForEdit }));
      dispatch(actionProject.projectFetched({ projectForEdit: response.project }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_PRODUCT, dispatch);
    });
};

export const deleteProduct =
  ({ context, id }) =>
  (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .deleteProduct(id)
      .then((response) => {
        processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_PRODUCT, dispatch);
        dispatch(actions.productDeleted({ id }));
        if (context) {
          dispatch(contextualActions[context].productDeleted({ id }));
        }
      })
      .catch((error) => {
        // error.displayMessage = "Can't delete product";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_PRODUCT, dispatch);
      });
  };

export const createProduct = (productForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProduct(productForCreation)
    .then((response) => {
      console.log("createProduct response: ", response);
      const product = { ...productForCreation, ...response };
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_PRODUCT, dispatch);
      dispatch(actions.productCreated({ product }));
      return product;
    })
    .catch((error) => {
      // error.displayMessage = "Can't create product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_PRODUCT, dispatch);
    });
};

export const updateProductFieldLocally = (key, value) => (dispatch) => {
  dispatch(actions.productFieldUpdatedLocally({ key, value }));
};

export const updateProduct = (productForEdit) => (dispatch) => {
  dispatch(save());
  return requestFromServer
    .updateProduct(productForEdit)
    .then((response) => {
      console.log("update product response", response);
      const product = { ...productForEdit, ...response };
      dispatch(actions.productUpdated({ product }));
      dispatch(actionProject.projectFetched({ projectForEdit: product.project }));
      dispatch(saved());
    })
    .catch((error) => {
      // error.displayMessage = "Can't update product";
      dispatch(saved());
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_PRODUCT, dispatch);
    });
};

export const uploadFile = (fileForCreation, sessionId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createFile(fileForCreation, sessionId)
    .then((response) => {
      const file = { ...response };
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPLOAD_FILE, dispatch);

      dispatch(actions.fileCreated({ file }));
    })
    .catch((error) => {
      console.log("error occurred", error);
      // error.displayMessage = "Can't create file";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.ADD_FILE, dispatch);
    });
};

export const updateFile = (fileForEdit) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateFile(fileForEdit)
    .then((response) => {
      const file = { ...response };
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPDATE_FILE, dispatch);

      dispatch(actions.fileUpdated({ file }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_FILE, dispatch);
    });
};

export const openFileViewer = (file, openFileViewerDialog) => (dispatch) =>
  requestFromServer
    .getFile(file)
    .then((response) => {
      file = file.friendlyName ? file : response;

      openFileViewerDialog({
        file,
        fileUrl: response.url,
      });
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.OPEN_FILE, dispatch);
    });

export const addLinkedFiles = (props) => (dispatch) => dispatch(actions.linkedFilesAdded(props));
export const removeLinkedFile = (props) => (dispatch) => dispatch(actions.linkedFileRemoved(props));

export const deleteFile = (file) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteFile(file)
    .then(() => {
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_FILE, dispatch);

      dispatch(actions.fileDeleted({ file }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_FILE, dispatch);
    });
};

export const fetchPhotos = (productId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPhotosByProductId(productId)
    .then((response) => {
      dispatch(actions.photosFetched(response));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FETCH_PHOTOS, dispatch);
    });
};

export const photosOrderUpdate = (productId, photosOrder) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  return requestFromServer
    .updatePhotosOrder(productId, photosOrder)
    .then((response) => {
      dispatch(actions.photosOrderUpdate(response));
    })
    .catch((error) => {
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.ORDER_PHOTO, dispatch);
      console.log("ProductActionsError", error);
    });
};

export const createPhoto =
  ({ parentId: productId, typePhoto, file }) =>
  (dispatch) => {
    const photo = { productId, typePhoto, file };
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .createPhoto(photo)
      .then((response) => {
        const { id, updatedAt, createdByUserId, photoSharings, isPrivate } = response;
        const newPhoto = {
          id,
          photo: `products/${productId}/${id}/photo.${typePhoto}`,
          localPhoto: URL.createObjectURL(file),
          updatedAt,
          productId,
          createdByUserId,
          isPrivate,
          photoSharings,
          typePhoto,
        };
        dispatch(actions.photoCreated({ newPhoto }));
        processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_PHOTO, dispatch);
        return newPhoto;
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_PHOTO, dispatch);
      });
  };

export const updatePhoto = (photo) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePhoto(photo)
    .then((response) => {
      dispatch(actions.photoUpdated({ photo: response }));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_PHOTO, dispatch);
    });
};

export const deletePhoto =
  ({ parentId: productId, photoId }) =>
  (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .deletePhoto({ productId, photoId })
      .then(() => {
        processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_PHOTO, dispatch);
        dispatch(actions.photoDeleted({ photoId }));
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_PHOTO, dispatch);
      });
  };

export const createPhotoSharing = (photoSharing) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createPhotoSharing(photoSharing)
    .then(() => {
      dispatch(actions.photoSharingCreated({ photoSharing }));
    })
    .catch(() => {
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_PHOTO_SHARING, dispatch);
    });
};

export const removePhotoSharing = (photoSharing) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePhotoSharing(photoSharing)
    .then(() => {
      dispatch(actions.photoSharingRemoved({ photoSharing }));
    })
    .catch(() => {
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_PHOTO_SHARING, dispatch);
    });
};

//----------------------------------------------------------------------------//

export const requestProductFileSignature = (signatureRequest) => (dispatch) =>
  requestFileSignature(actions, signatureRequest, dispatch, callTypes);

export const cancelProductFileSignaturesRequest = (signaturesRequest) => (dispatch) =>
  cancelFileSignaturesRequest(actions, signaturesRequest, dispatch, callTypes);

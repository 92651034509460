// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import * as React from "react";
import { Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { Input, Select } from "../../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { LEAD_FILE_TYPES } from "../../../../../../_utils/listUtils";
import { canEdit, canReadAll, canReadAllDocument } from "../../../../../../_utils/authUtils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Checkbox } from "../../../../../../_components/Checkbox";
import { LinkedFiles } from "../../../../../../_components/LinkedFiles";
import { FileDropzone } from "../../../../../../_components/FileDropzone";
import { useLeadFilesUIContext } from "../LeadFilesUIContext";
import * as actions from "../../../../_redux/leads/leadsActions";
import { useDidMount, useWillUnmount } from "rooks";
import { Notification } from "../../../../../../_components/Notification";
import { UserType } from "../../../../../../../data/schemas";
import { TagManager } from "../../../../../../_components/TagManager";
import { FILE, TAG } from "../../../../../../_utils/dataTypes";
import { UploadFileFormValidationSchema } from "../../../forms/UploadFileFormValidationSchema";

export function LeadFileEditForm({ file }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    setIds,
    closeEditFileDialog,
    newFile,
    setFormSubmitRef,
    resetFormSubmitRef,
    setIsSubmitDisabled,
  } = useLeadFilesUIContext();

  const { session, groups, lead, actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.leads.actionsLoading,
      session: state.auth.session,
      groups: state.auth.groups,
      lead: state.leads.leadForEdit.saved,
    }),
    shallowEqual
  );

  const formRef = React.useRef(null);

  useDidMount(() => {
    if (!setFormSubmitRef) return;
    setFormSubmitRef(() => {
      const form = formRef.current;
      if (form) {
        form.submitForm();
      }
    });
  });

  useWillUnmount(() => {
    resetFormSubmitRef();
  });

  React.useEffect(() => {
    setIsSubmitDisabled(actionsLoading);
  }, [actionsLoading]);

  const submitForm = (values) => {
    values = { ...values, notifyUser: values?.notifyUser ?? [] };
    if (newFile) {
      const leadFile = { ...values, relatedEntity: { name: lead.name } };
      dispatch(actions.uploadFile(leadFile, session?.id)).then(() => {
        setIds([]);
        closeEditFileDialog();
      });
    } else {
      dispatch(actions.updateFile(values)).then(() => {
        setIds([]);
        closeEditFileDialog();
      });
    }
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={file}
        validationSchema={UploadFileFormValidationSchema({ newFile })}
        onSubmit={submitForm}
      >
        {({ setFieldValue, values, handleChange }) => (
          <Modal.Body className="overlay">
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-12">
                  <Field
                    name="friendlyName"
                    component={Input}
                    placeholder={intl.formatMessage({
                      id: "COMMON.NAME",
                    })}
                    label={intl.formatMessage({
                      id: "COMMON.NAME",
                    })}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                  <Select
                    name="fileType"
                    label={intl.formatMessage({
                      id: "COMMON.TYPE",
                    })}
                  >
                    {Object.keys(LEAD_FILE_TYPES)
                      .filter((key) => key !== "INVOICE")
                      .map((value) => (
                        <option key={value} value={value}>
                          {intl.formatMessage({ id: LEAD_FILE_TYPES[value] })}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>

              {newFile && (
                <div className="form-group row">
                  <div className="col-lg-12">
                    {actionsLoading && !!values.file ? (
                      <div className="upload-file-container upload-file-container--disabled">
                        <div className="d-flex justify-content-center align-middle">
                          <div className="spinner-grow text-primary mr-4" />
                          <div>
                            <FormattedMessage id="FILE.ACTION.UPLOADING" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <FileDropzone values={values} setFieldValue={setFieldValue} />
                    )}
                  </div>
                </div>
              )}
              {canReadAll(groups, session, TAG) && !newFile && (
                <div className="form-group row">
                  <div className="col-lg-12" data-cy="select-custom-tags">
                    <TagManager fullWidth relatedTo={`${FILE}#${file.id}`} />
                  </div>
                </div>
              )}

              {canEdit(groups, session, "BUDGET") && (
                <Checkbox
                  name={"selectedForFinance"}
                  label={"FILE.ACTION.SELECT_FOR_FINANCE_ANALYSIS"}
                  customClasses={"mb-2"}
                />
              )}
              {canReadAllDocument(groups, session, "LEAD") && (
                <Checkbox name={"isConfidential"} label={"COMMON.CONFIDENTIAL.DOCUMENT"} />
              )}
              {!values.isConfidential && (
                <div className="form-group row mt-4">
                  <div className="col-lg-12">
                    <Notification
                      notifyMethods={values.notifyUser}
                      userType={UserType.CLIENT}
                      setNotifyMethods={(options) =>
                        handleChange({
                          target: {
                            name: "notifyUser",
                            value: options,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              )}
              <div className="separator separator-solid separator-border-2 my-4"></div>
              <LinkedFiles activeFile={file} />
            </Form>
          </Modal.Body>
        )}
      </Formik>
    </>
  );
}

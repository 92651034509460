/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from "react";
import { initialFilter } from "./UserFilesUIHelper";
import { useFilesContext } from "../../../../File/FilesProvider";
import { canEdit, canReadAll, canReadAllDocument } from "../../../../../_utils/authUtils";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../../../../Settings/_redux/settingsActions";
import logout from "../../../../Auth/pages/Logout";
import { LeadFinancialDocumentLineType, UnitQuantity } from "../../../../../../data/schemas";
import { SearchTypeValues } from "../../../../../contexts/SearchContext";
import { filterUITag } from "../../../../../_utils/search/searchUtils";
import { useTag } from "../../../../../hooks/useTag";
import { FILE, TAG } from "../../../../../_utils/dataTypes";

const UserFilesUIContext = createContext();

export function useUserFilesUIContext() {
  return useContext(UserFilesUIContext);
}

export const UserFilesUIConsumer = UserFilesUIContext.Consumer;

export function UserFilesUIProvider({ currentUserId, children, readOnly, canUpload, userTypes }) {
  const dispatch = useDispatch();
  const filesContext = useFilesContext();
  const { setShowEditFileDialog, setSelectedId, setUploadingFile } = filesContext;

  const [userId, setUserId] = useState(currentUserId);
  const [filters, setFilters] = useState(initialFilter);
  const { tagLinks } = useTag();

  const { groups, session, settings } = useSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
    settings: state.settings.settingsForEdit.saved,
  }));

  useEffect(() => {
    if (!settings) {
      fetchSettings(dispatch).catch(() => {
        logout();
      });
    }
  }, [settings]);

  const initFile = {
    id: undefined,
    friendlyName: "",
    file: undefined,
    fileBack: undefined,
    fileType: "-",
    userId,
    userType: userTypes?.[0],
    visible: true,
    isConfidential: canReadAllDocument(groups, session, "USER"),
    selectedForFinance: canEdit(groups, session, "BUDGET"),
    enableReInvoicing: false,
    invoiceStatus: undefined,
    dueDate: undefined,
    amountPaidToDate: undefined,
  };

  const financeFileDefaultLine = () => ({
    id: uuid(),
    vat: settings?.defaultVat ?? 0,
    grossAmount: 0,
    amount: 0,
    discount: 0,
    discountType: "absolute",
    budgetLineId: undefined,
    budgetLineLabel: undefined,
  });

  const financeFileDefaultCategory = () => ({
    budgetLineCategoryId: undefined,
    budgetLineCategoryLabel: undefined,
    lines: [financeFileDefaultLine()],
  });

  const reInvoicePriceLine = () => ({
    id: uuid(),
    label: "",
    unitPrice: 0,
    margin: 0,
    marginType: "absolute",
    quantity: 1,
    unit: UnitQuantity.PIECES,
    vat: settings?.defaultVat ?? 0,
    lineType: LeadFinancialDocumentLineType.PRICING,
  });

  const reInvoiceDescriptionLine = () => ({
    id: uuid(),
    label: "",
    lineType: LeadFinancialDocumentLineType.DESCRIPTION,
  });

  useEffect(() => {
    initFile.userId = currentUserId;
    setUserId(currentUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  const openRequestFileDialog = () => {
    setSelectedId(undefined);
    setUploadingFile(false);
    setShowEditFileDialog(true);
  };
  const openUploadFileDialog = (id) => {
    setSelectedId(id);
    setUploadingFile(true);
    setShowEditFileDialog(true);
  };
  const value = {
    ...filesContext,
    userId,
    setUserId,
    filters,
    setFilters,
    initFile,
    openRequestFileDialog,
    openUploadFileDialog,
    readOnly,
    canUpload,
    userTypes,
    financeFileDefaultLine,
    financeFileDefaultCategory,
    reInvoicePriceLine,
    reInvoiceDescriptionLine,
    filtersUI: [
      {
        name: "COMMON.SEARCH.TITLE",
        value: "text",
        type: SearchTypeValues.SEARCH_TEXT_COLUMNS,
        columns: [...filesContext.filterList],
        checkTag: true,
      },
      {
        name: "COMMON.TYPE",
        type: SearchTypeValues.SEARCH_DROPDOWN,
        value: "fileType",
        values: [],
        labelKey: `USER.FILE.TYPES.${userTypes}`,
      },
      { name: "COMMON.UPDATED.AT", value: "uploadedAt", type: SearchTypeValues.SEARCH_DATE },
    ],
  };
  if (canReadAll(groups, session, TAG)) value.filtersUI.push(filterUITag(FILE, tagLinks));

  return <UserFilesUIContext.Provider value={value}>{children}</UserFilesUIContext.Provider>;
}

import { IImmotoolEntity } from "./ImmotoolEntity";
import { IAddress } from "./Address";
import { IFile } from "./File";
import { IUser } from "./User";
import { IProduct } from "./Product";
import { FinanceFileType, IProject } from "./Project";
import { IBudgetInstalment } from "./Budget";
import { IPriceIndex } from "./PriceIndex";

export enum LeadStatus {
  "-" = "-",
  LOST = "LOST",
  OPEN = "OPEN",
  QUALIFIED = "QUALIFIED",
  WON = "WON",
}

export enum UnitQuantity {
  PIECES = "pcs",
  LINEAR_METER = "m",
  SQUARE_METER = "m2",
  CUBIC_METER = "m3",
}

export interface ILead extends IImmotoolEntity {
  productId: string;
  projectId: string;
  name: string;
  subject: string;
  status: LeadStatus;
  tasksDone: string[];
  notes: string;
  invoiceAddressType: string;
  invoiceAddress: { name: string; address: IAddress };
  sellingPrice: number;
  shareOfLandSellingPrice: number;
  architectEngineeringFees: number;
  eligibleReducedVat: boolean;
  authorisationCode?: string;
  authorisationDate?: Date;
  constructionPriceWithReducedVat?: number;
  files?: ILeadFile[];
  users: IUser[];
  product: IProduct;
  project: IProject;
  financialDocuments: ILeadFinancialDocument[];
  budgetInstalments?: IBudgetInstalment[];
  baseLeadPriceIndex: IPriceIndex;
}

export interface ILeadFile extends IFile {
  leadId: string;
  leadFinancialDocumentId?: string;
  invoiceStatus?: string;
  dueDate?: Date | null;
  amount?: number;
  amountPaidToDate?: number;
  authorisationCode?: string;
  selectedForFinance?: boolean;
}

export interface ILeadFinancialDocument extends IImmotoolEntity {
  id: string;
  fileType: FinanceFileType;
  leadId: string;
  projectId: string;
  propCoId: string;
  title: string;
  dueDate: Date | null;
  invoiceStatus: string;
  isConfidential: boolean;
  from: { name: string; address: IAddress; email: string; mobile: string };
  to: { name: string; address: IAddress };
  product: { projectName: string; productName: string; address: IAddress };
  tax: number;
  content: LeadFinancialDocumentLine[];
  bankAccounts: string[];
  authorisationCode: string;
  selectedForFinance: boolean;
  basePriceIndex?: IPriceIndex;
  priceIndex?: IPriceIndex;
  notes?: string;
  subject?:string;
}

export enum LeadFinancialDocumentLineType {
  PRICING = "PRICING",
  DESCRIPTION = "DESCRIPTION",
}

export type TRelatedFinancialDocumentLine = Omit<
  LeadFinancialDocumentLine,
  "relatedFinancialDocuments" | "budgetInstalmentId"
>;
export interface IRelatedFinancialDocument {
  financialDocumentId?: string;
  title: string;
  content: TRelatedFinancialDocumentLine[];
}

export interface LeadFinancialDocumentLine {
  id: string;
  budgetInstalmentId?: string;
  financialDocumentId?: string;
  label: string;
  lineType: LeadFinancialDocumentLineType;
  relatedFinancialDocuments?: IRelatedFinancialDocument[];
}

export interface LeadFinancialDocumentPriceLine extends LeadFinancialDocumentLine {
  quantity?: number;
  unit?: UnitQuantity;
  unitPrice?: number;
  vat: number;
  amount: number;
  lineType: LeadFinancialDocumentLineType.PRICING;
  margin?: number;
  hasMixVat?: boolean;
  hasWarningVat?: boolean;
  indexedAmount: number;
}

export const isLeadFinancialDocumentPriceLine = (
  line: any
): line is LeadFinancialDocumentPriceLine =>
  line.lineType === LeadFinancialDocumentLineType.PRICING;

export const InvoiceStatus = {
  PAID: "PAID",
  PARTIALLY_PAID: "PARTIALLY_PAID",
  TO_BE_PAID: "TO_BE_PAID",
  OVERDUE: "OVERDUE",
} as const;
export type TInvoiceStatus = keyof typeof InvoiceStatus;

export const paymentStatusStyle: Record<TInvoiceStatus, { translationKey: string; color: string }> =
  {
    PAID: {
      translationKey: "INVOICE.STATUS.PAID",
      color: "success",
    },
    PARTIALLY_PAID: {
      translationKey: "INVOICE.STATUS.PARTIALLY_PAID",
      color: "info",
    },
    TO_BE_PAID: {
      translationKey: "INVOICE.STATUS.TO_BE_PAID",
      color: "warning",
    },
    OVERDUE: {
      translationKey: "INVOICE.STATUS.OVERDUE",
      color: "danger",
    },
  };
